export default [
  {
    prop: 'id',
    label: 'Mã',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'name',
    label: 'Sự kiện',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'total',
    label: 'Email',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'start_at',
    label: 'Bắt đầu',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'end_at',
    label: 'Kết thúc',
    minWidth: 120,
    type: 'text'
  }
]
