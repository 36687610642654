<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="mails" :actions="actions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { MessageBox, Message } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import schemas from './history-schemas'
  import dtHelper from 'src/helpers/datatable'
  import { mapActions } from 'vuex'

  export default{
    components: {
      MyTable,
      MyFilter
    },
    computed: {
      mails() {
        let rows = this.$store.state.sendMailHistory;
        return dtHelper.filterByRules(rows, this.filterOutput);
      },
    },
    data () {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(schemas),
        actions: [],
        filter: {},
        rules: dtHelper.buildRules(schemas)
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchSendMailHistory');
      this.$store.dispatch('setPageTitle', 'Lịch sử gửi mail');
      this.$store.dispatch('setCurrentActions', []);
    },
    methods: {
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
